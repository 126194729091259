import React from 'react';
import suple from '../../images/suple.jpg'
import suple2 from '../../images/suple2.jpg'
export default function WhoWeAre() {
  return (
    <>
    <section style={styles.section}>
      <div style={styles.container}>
        {/* Image Section */}
        <div style={styles.imageContainer}>
          <img
            src={suple}
            alt="Who We Are"
            style={styles.image}
          />
        </div>

        {/* Text Section */}
        <div style={styles.textContainer}>
          <h2 style={styles.heading}>Who We Are</h2>
          <p style={styles.paragraph}>
            Welcome to <strong>PayNet Enterprise</strong>
          </p>
          <p style={styles.paragraph}>
            Our mission is to provide you with the tools and resources to build your body for whatever health and wellness goal you set your sights on. From programs and articles to supplements and gear, every part of your routine starts here.
          </p>
          <p style={styles.paragraph}>
            Build your body. Build your mind. Build your life.
          </p>
          <p style={styles.paragraph}>
            Let’s get to work.
          </p>
        </div>
      </div>
    </section>

     <section className='mt-4' style={styles.section}>
     <div style={styles.container}>
       {/* Image Section */}
      

       {/* Text Section */}
       <div style={styles.textContainer}>
  <h2 style={styles.heading}>About Us</h2>
  <p style={styles.paragraph}>
     <strong>PayNet Enterprise</strong>, where your journey to health and wellness begins.
  </p>
  <p style={styles.paragraph}>
    Our Vission is simple: to empower you with everything you need to achieve your fitness goals. Whether you're looking for expert programs, insightful articles, top-quality supplements, or premium gear, we’ve got you covered. Every aspect of your wellness journey starts right here.
  </p>
  <p style={styles.paragraph}>
    Transform your body. Strengthen your mind. Elevate your life.
  </p>
  <p style={styles.paragraph}>
    It's time to take action and unlock your full potential.
  </p>
</div>

       <div style={styles.imageContainer}>
         <img
           src={suple2}
           alt="Who We Are"
           style={styles.image}
         />
       </div>
     </div>
   </section>
   </>
  );
}

const styles = {
  section: {
    padding: '50px 20px',
    backgroundColor: '#f8f8f8',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '40px',
    maxWidth: '1200px',
    margin: '0 auto',
    flexWrap: 'wrap',
  },
  imageContainer: {
    flex: '1',
    minWidth: '300px',
  },
  image: {
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  },
  textContainer: {
    flex: '1',
    minWidth: '300px',
    textAlign: 'left',
  },
  heading: {
    fontSize: '32px',
    color: '#012246',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '18px',
    color: '#333',
    lineHeight: '1.6',
    marginBottom: '10px',
  },
};
