import React from "react";
import Footer from "../Sub/Footer";
import webBanner from "../../images/webBanner.jpg";
import CommonHeader from "../Sub/CommonHeader";
import Banner from "../Sub/Banner";
import { Col, Row } from "react-bootstrap";


const About = () => {
  

 
  let width = window.innerWidth;
  return (
    <>
      <CommonHeader />
      <div  className="img-about">
      <div  className="content-about">
        <h1>Who We Are</h1>
        <h5 className="about-f">
          PayNet Enterprise is the premier sports nutrition and dietary supplements company specialised in supplements, Vitamins, and Herbal products. <br /><br />
          PayNet Enterprise is a fully licensed nutritional and sports products manufacturing company. We offer a comprehensive package of sports product brands and services. <br /><br />
          All our products are processed under current Good Manufacturing practice (cGMP’s) guidelines along with the strict USFDA and state department regulations. Whether it's bodybuilding, losing weight, or simply dedicating yourself to a healthier lifestyle. <br /><br />
          PayNet Enterprise provides the best products and services to feed your passion.
        </h5>
      </div>
    </div>

      <Footer />
    </>
  );
};

export default About;
